import {useEffect} from "react";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.location.href = "/privacyPolicy.html";
    }, []);

    return null;
};
export default PrivacyPolicy;